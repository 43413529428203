import React from 'react';
import './servicos.css';
import { SlideInSection } from '../slideInsection/slideInsection';
import { Language } from '../../App';
const servicesTranslations = {
  pt: [
    {
      icon: `${process.env.PUBLIC_URL}/web.png`,
      title: 'Aplicações Web e Desktop',
      description: 'Desenvolvemos soluções web e desktop personalizadas: websites, ERP/CRM, e-commerce, e integração de sistemas.'
    },
    {
      icon: `${process.env.PUBLIC_URL}/mobile.png`,
      title: 'Aplicações Mobile',
      description: 'Desenvolvemos aplicativos móveis personalizados: Android, iOS, híbridos, UI/UX, e manutenção contínua.'
    },
    {
      icon: `${process.env.PUBLIC_URL}/db.png`,
      title: 'Serviços em Banco de Dados',
      description: 'Oferecemos design, otimização, migração, backup e administração de bancos de dados, com segurança e eficiência.'
    },
    {
      icon: `${process.env.PUBLIC_URL}/api.png`,
      title: 'Plataformas e APIs',
      description: 'Criamos e integramos APIs seguras, desenvolvemos microsserviços e oferecemos manutenção e monitoramento contínuos.'
    }
  ],
  en: [
    {
      icon: `${process.env.PUBLIC_URL}/web.png`,
      title: 'Web and Desktop Applications',
      description: 'We develop custom web and desktop solutions: websites, ERP/CRM, e-commerce, and system integration.'
    },
    {
      icon: `${process.env.PUBLIC_URL}/mobile.png`,
      title: 'Mobile Applications',
      description: 'We develop custom mobile apps: Android, iOS, hybrid, UI/UX, and continuous maintenance.'
    },
    {
      icon: `${process.env.PUBLIC_URL}/db.png`,
      title: 'Database Services',
      description: 'We offer design, optimization, migration, backup, and database administration, with security and efficiency.'
    },
    {
      icon: `${process.env.PUBLIC_URL}/api.png`,
      title: 'Platforms and APIs',
      description: 'We create and integrate secure APIs, develop microservices, and offer continuous maintenance and monitoring.'
    }
  ]
};

const sectionTitleTranslations = {
  pt: "Serviços que oferecemos que revolucionam qualquer negócio.",
  en: "Services we offer that revolutionize any business."
};






interface laguageProps {
  language: Language;
}

const Services: React.FC<laguageProps> = ({ language }) => {
  const services = servicesTranslations[language];
  const sectionTitle = sectionTitleTranslations[language];

  return (
    <div className="services-container">
      <h2 className="services-title">{sectionTitle}</h2>
      <div className="services-list">
        {services.map((service, index) => (
          <SlideInSection key={index} direction="left">
            <div key={index} className="service-item">
              <div className="service-icon-container">
                <img src={service.icon} alt={`Ícone de ${service.title}`} className="service-icon" />
              </div>
              <div className="service-text">
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
              </div>
            </div>
          </SlideInSection>
        ))}
      </div>
    </div>
  );
};

export default Services;



