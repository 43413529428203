import React from 'react';
import './tecnologias.css'
import { SlideInSection } from '../slideInsection/slideInsection';
import { Language } from '../../App';
const sectionTitleTranslations = {
  pt: "Tecnologias que usamos para desenvolver suas aplicações.",
  en: "Technologies we use to develop your applications."
};

const technologies = [
  { name: 'Azure DevOps', logo: `${process.env.PUBLIC_URL}/logos/azure.png` },
  { name: 'Node.js', logo: `${process.env.PUBLIC_URL}/logos/nodejs.png` },
  { name: 'CSharp', logo: `${process.env.PUBLIC_URL}/logos/csharp.png` },
  { name: 'Docker', logo: `${process.env.PUBLIC_URL}/logos/docker.png` },
  { name: 'Python', logo: `${process.env.PUBLIC_URL}/logos/python.png` },
  { name: 'Amazom Web Services', logo: `${process.env.PUBLIC_URL}/logos/aws.png` },
  { name: 'MySQL', logo: `${process.env.PUBLIC_URL}/logos/mysql.png` },
  { name: 'ASP.NET', logo: `${process.env.PUBLIC_URL}/logos/aspnet.png` },
  { name: 'React', logo: `${process.env.PUBLIC_URL}/logos/react.png` },
  { name: 'SQL Server', logo: `${process.env.PUBLIC_URL}/logos/sqlserver.png` },
  { name: 'Flutter', logo: `${process.env.PUBLIC_URL}/logos/flutter.png` },
];
interface laguageProps {
  language: Language;
}
const Tecnologias: React.FC<laguageProps> = ({ language }) => {

  const sectionTitle = sectionTitleTranslations[language];
  return (
    <div className="technologies-container">
      <h2 className="technologies-title"> {sectionTitle} </h2>
      <div className="technologies-list">
        {technologies.map((tech, index) => (

          <div key={index} className="technology-item">
            <SlideInSection key={index} direction="right">
              <img src={tech.logo} alt={tech.name} className="technology-logo" />
              <p className="technology-name">{tech.name}</p>
            </SlideInSection>

          </div>


        ))}
      </div>
    </div>
  );
};

export default Tecnologias;
