import React from 'react';
import './footer.css';
import { Language } from '../../App';
const footerTranslations = {
  pt: {
    title: "SERVIÇOS",
    sections: [
      {
        title: "Aplicações Web e Desktop",
        items: [
          "Desenvolvimento de websites e portais",
          "Criação de sistemas corporativos",
          "Soluções de e-commerce",
          "Aplicações de gestão (ERP, CRM)",
          "Integração de sistemas",
        ],
      },
      {
        title: "Aplicações Mobile",
        items: [
          "Desenvolvimento de aplicativos Android",
          "Desenvolvimento de aplicativos iOS",
          "Aplicativos híbridos com Flutter",
          "Integração com interfaces móveis",
          "Manutenção e atualização de aplicativos",
        ],
      },
      {
        title: "Serviço de banco de dados",
        items: [
          "Design e implementação de bancos de dados",
          "Otimização e manutenção de bancos de dados",
          "Migração de dados",
          "Segurança de dados",
          "Administração de banco de dados (DBA)",
        ],
      },
      {
        title: "Plataformas e APIs",
        items: [
          "Criação e documentação de APIs",
          "Integração de APIs de terceiros",
          "Desenvolvimento de microsserviços",
          "Segurança e autenticação",
          "Monitoramento e manutenção de APIs",
        ],
      },
    ],
    salesTitle: "CENTRAL DE VENDAS",
    supportTitle: "SUPORTE TÉCNICO",
    followTitle: "SIGA & COMPARTILHE",
  },
  en: {
    title: "SERVICES",
    sections: [
      {
        title: "Web and Desktop Applications",
        items: [
          "Website and portal development",
          "Corporate system creation",
          "E-commerce solutions",
          "Management applications (ERP, CRM)",
          "System integration",
        ],
      },
      {
        title: "Mobile Applications",
        items: [
          "Android app development",
          "iOS app development",
          "Hybrid apps with Flutter",
          "Integration with mobile interfaces",
          "App maintenance and updates",
        ],
      },
      {
        title: "Database Services",
        items: [
          "Database design and implementation",
          "Database optimization and maintenance",
          "Data migration",
          "Data security",
          "Database administration (DBA)",
        ],
      },
      {
        title: "Platforms and APIs",
        items: [
          "API creation and documentation",
          "Third-party API integration",
          "Microservices development",
          "Security and authentication",
          "API monitoring and maintenance",
        ],
      },
    ],
    salesTitle: "SALES CENTER",
    supportTitle: "TECHNICAL SUPPORT",
    followTitle: "FOLLOW & SHARE",
  },
};



interface laguageProps {
  language: Language;
}

const Footer: React.FC<laguageProps> = ({ language }) => {
  const translations = footerTranslations[language];

  return (
    <footer className="footer-container">
      <h4 className='titulo-footer'>{translations.title}</h4>
      <div className="footer-content">
        {translations.sections.map((section, index) => (
          <div className="footer-section" key={index}>
            <h4>{section.title}</h4>
            <ul>
              {section.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
        <div className="footer-section">
          <h4>{translations.salesTitle}</h4>
          <p><strong>BELO HORIZONTE</strong><br />
            +55 (31) 9 9231-1904<br />
            <a href="mailto:comercial@neogenesisdigital.com">comercial@neogenesisdigital.com</a></p>
          <p><strong>{translations.supportTitle}</strong><br />
            <a href="mailto:support@neogenesisdigital.com">suport@neogenesisdigital.com</a></p>
          <h4>{translations.followTitle}</h4>
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/neo-geness-digital/" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/linkedi.png`} width="114" height="60" alt="LinkedIn" /></a>
            <a href="https://www.instagram.com/neo_geness?igsh=YncwdWJvNW5xMzgz" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/instagram.png`} width="114" height="46" alt="Instagram" /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
