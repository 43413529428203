import React, { useState, useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar';
import './load.css'; // Estilo personalizado

function Loading() {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(100);
    }, []);

    return (
        <div className="loading-container">
            <LoadingBar
                progress={progress}
                height={3}
                color="#1e3d59"
                onLoaderFinished={() => setProgress(0)}
                data-preset="bubble"
            />
            <div className="loading-content">
                <div className="water-fill">
                    <div className="water-fill2">
                    <img src="logoneogenss.svg" alt="Logo" className="loading-logo" />
                    <h1 className="loading-text">Neo Geness</h1>
                    </div>
                    
                </div>

            </div>
        </div>
    );
}

export default Loading;
