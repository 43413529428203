
import React, { useState } from 'react';
import "./fomularios.css";
import useEmailAPI from './../../hooks/apiEmal'; // Importar o hook que você criou
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import { Language } from '../../App';
const formTranslations = {
  pt: {
    emailLabel: "Email",
    emailPlaceholder: "Digite seu email",
    sending: "Enviando...",
    send: "Enviar",
    successMessage: "Email enviado com sucesso!",
    errorMessage: "Erro: ",
    nameLabel: "Nome",
    namePlaceholder: "Digite seu nome",
    phoneLabel: "Telefone",
    phonePlaceholder: "Digite seu telefone",
    companyLabel: "Empresa",
    companyPlaceholder: "Nome da empresa",
    projectTypeLabel: "Tipo de Projeto",
    projectTypePlaceholder: "Selecione o tipo de projeto",
    budgetLabel: "Orçamento Estimado",
    budgetPlaceholder: "Digite seu orçamento estimado",
    messageLabel: "Mensagem",
    messagePlaceholder: "Descreva brevemente seu projeto ou necessidade",
    optgroupWebDesktop: "Aplicações Web e Desktop",
    optgroupMobile: "Aplicações Mobile",
    optgroupDatabase: "Serviço de Banco de Dados",
    optgroupAPI: "Plataformas e APIs",
    options: {
      websitesPortais: "Desenvolvimento de websites e portais",
      sistemasCorporativos: "Criação de sistemas corporativos",
      ecommerce: "Soluções de e-commerce",
      gestaoERPCRM: "Aplicações de gestão (ERP, CRM)",
      integracaoSistemas: "Integração de sistemas",
      androidApps: "Desenvolvimento de aplicativos Android",
      iosApps: "Desenvolvimento de aplicativos iOS",
      flutterApps: "Aplicativos híbridos com Flutter",
      integracaoInterfacesMoveis: "Integração com interfaces móveis",
      manutencaoAtualizacaoApps: "Manutenção e atualização de aplicativos",
      designImplementacaoBD: "Design e implementação de bancos de dados",
      otimizacaoManutencaoBD: "Otimização e manutenção de bancos de dados",
      migracaoDados: "Migração de dados",
      segurancaDados: "Segurança de dados",
      dba: "Administração de banco de dados (DBA)",
      documentacaoAPIs: "Criação e documentação de APIs",
      integracaoAPIs: "Integração de APIs de terceiros",
      microsservicos: "Desenvolvimento de microsserviços",
      segurancaAutenticacao: "Segurança e autenticação",
      monitoramentoAPIs: "Monitoramento e manutenção de APIs"
    }
  },
  en: {
    emailLabel: "Email",
    emailPlaceholder: "Enter your email",
    sending: "Sending...",
    send: "Send",
    successMessage: "Email sent successfully!",
    errorMessage: "Error: ",
    nameLabel: "Name",
    namePlaceholder: "Enter your name",
    phoneLabel: "Phone",
    phonePlaceholder: "Enter your phone",
    companyLabel: "Company",
    companyPlaceholder: "Company name",
    projectTypeLabel: "Project Type",
    projectTypePlaceholder: "Select project type",
    budgetLabel: "Estimated Budget",
    budgetPlaceholder: "Enter your estimated budget",
    messageLabel: "Message",
    messagePlaceholder: "Briefly describe your project or need",
    optgroupWebDesktop: "Web and Desktop Applications",
    optgroupMobile: "Mobile Applications",
    optgroupDatabase: "Database Services",
    optgroupAPI: "Platforms and APIs",
    options: {
      websitesPortais: "Website and portal development",
      sistemasCorporativos: "Corporate system creation",
      ecommerce: "E-commerce solutions",
      gestaoERPCRM: "Management applications (ERP, CRM)",
      integracaoSistemas: "System integration",
      androidApps: "Android app development",
      iosApps: "iOS app development",
      flutterApps: "Hybrid apps with Flutter",
      integracaoInterfacesMoveis: "Integration with mobile interfaces",
      manutencaoAtualizacaoApps: "App maintenance and updates",
      designImplementacaoBD: "Database design and implementation",
      otimizacaoManutencaoBD: "Database optimization and maintenance",
      migracaoDados: "Data migration",
      segurancaDados: "Data security",
      dba: "Database administration (DBA)",
      documentacaoAPIs: "API creation and documentation",
      integracaoAPIs: "Third-party API integration",
      microsservicos: "Microservices development",
      segurancaAutenticacao: "Security and authentication",
      monitoramentoAPIs: "API monitoring and maintenance"
    }
  }
};












interface laguageProps {
  language: Language;
}




export interface NewsletterFormData {
  email: string;
}

interface NewsletterFormProps extends laguageProps {
  onSubmit: (data: NewsletterFormData) => void;
}

export const NewsletterForm: React.FC<NewsletterFormProps> = ({ onSubmit, language }) => {
  const [newformData, setFormData] = useState<NewsletterFormData>({
    email: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const { sendEmailNewllater, loading, error, success } = useEmailAPI();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendEmailNewllater({
        email: newformData.email,
      });
      onSubmit(newformData);
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
    }
  };

  const translations = formTranslations[language];

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">{translations.emailLabel}</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder={translations.emailPlaceholder}
          value={newformData.email}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit" className="submit-button" disabled={loading}>
        {loading ? translations.sending : translations.send}
      </button>

      {error && <p style={{ color: 'red' }}>{translations.errorMessage}{error}</p>}
      {success && <p style={{ color: 'green' }}>{translations.successMessage}</p>}
    </form>
  );
};







export interface ContactFormData {
  name: string;
  email: string;
  phone: string;
  company: string;
  projectType: string;
  budget: string;
  message: string;
}

interface ContactFormProps extends laguageProps {
  onSubmit: (data: ContactFormData) => void;
}

export const ContactForm: React.FC<ContactFormProps> = ({ onSubmit, language }) => {
  const [formData, setFormData] = useState<ContactFormData>({
    name: '',
    email: '',
    phone: '',
    company: '',
    projectType: '',
    budget: '',
    message: '',
  });

  const validatePhone = () => {
    const phoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/;
    return phoneRegex.test(formData.phone);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { sendEmail, loading, error, success } = useEmailAPI();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (validatePhone()) {
        await sendEmail({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          company: formData.company,
          projectType: formData.projectType,
          budget: formData.budget,
          message: formData.message
        });
        onSubmit(formData);
      } else {
        console.log('Telefone inválido');
      }
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
    }
  };

  const translations = formTranslations[language];

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <div className="form-row">
        <div className="form-group half-width">
          <label htmlFor="name">{translations.nameLabel}</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder={translations.namePlaceholder}
            required
          />
        </div>

        <div className="form-group half-width">
          <label htmlFor="email">{translations.emailLabel}</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder={translations.emailPlaceholder}
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group half-width">
          <label htmlFor="phone">{translations.phoneLabel}</label>
          <InputMask
            mask="(99) 99999-9999"
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder={translations.phonePlaceholder}
            required
          />
        </div>

        <div className="form-group half-width">
          <label htmlFor="company">{translations.companyLabel}</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            placeholder={translations.companyPlaceholder}
            required
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="projectType">{translations.projectTypeLabel}</label>
        <select
          id="projectType"
          name="projectType"
          value={formData.projectType}
          onChange={handleChange}
          required
        >
          <option value="">{translations.projectTypePlaceholder}</option>

          <optgroup label={translations.optgroupWebDesktop}>
            <option value="websitesPortais">{translations.options.websitesPortais}</option>
            <option value="sistemasCorporativos">{translations.options.sistemasCorporativos}</option>
            <option value="ecommerce">{translations.options.ecommerce}</option>
            <option value="gestaoERPCRM">{translations.options.gestaoERPCRM}</option>
            <option value="integracaoSistemas">{translations.options.integracaoSistemas}</option>
          </optgroup>

          <optgroup label={translations.optgroupMobile}>
            <option value="androidApps">{translations.options.androidApps}</option>
            <option value="iosApps">{translations.options.iosApps}</option>
            <option value="flutterApps">{translations.options.flutterApps}</option>
            <option value="integracaoInterfacesMoveis">{translations.options.integracaoInterfacesMoveis}</option>
            <option value="manutencaoAtualizacaoApps">{translations.options.manutencaoAtualizacaoApps}</option>
          </optgroup>

          <optgroup label={translations.optgroupDatabase}>
            <option value="designImplementacaoBD">{translations.options.designImplementacaoBD}</option>
            <option value="otimizacaoManutencaoBD">{translations.options.otimizacaoManutencaoBD}</option>
            <option value="migracaoDados">{translations.options.migracaoDados}</option>
            <option value="segurancaDados">{translations.options.segurancaDados}</option>
            <option value="dba">{translations.options.dba}</option>
          </optgroup>

          <optgroup label={translations.optgroupAPI}>
            <option value="documentacaoAPIs">{translations.options.documentacaoAPIs}</option>
            <option value="integracaoAPIs">{translations.options.integracaoAPIs}</option>
            <option value="microsservicos">{translations.options.microsservicos}</option>
            <option value="segurancaAutenticacao">{translations.options.segurancaAutenticacao}</option>
            <option value="monitoramentoAPIs">{translations.options.monitoramentoAPIs}</option>
          </optgroup>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="budget">{translations.budgetLabel}</label>
        <input
          type="text"
          id="budget"
          name="budget"
          value={formData.budget}
          onChange={handleChange}
          placeholder={translations.budgetPlaceholder}
        />
      </div>

      <div className="form-group">
        <label htmlFor="message">{translations.messageLabel}</label>
        <textarea
          id="message"
          name="message"
          rows={4}
          value={formData.message}
          onChange={handleChange}
          placeholder={translations.messagePlaceholder}
          required
        ></textarea>
      </div>

      <button type="submit" className="submit-button" disabled={loading}>
        {loading ? translations.sending : translations.send}
      </button>

      {error && <p style={{ color: 'red' }}>{translations.errorMessage}{error}</p>}
      {success && <p style={{ color: 'green' }}>{translations.successMessage}</p>}
    </form>
  );
};