import React from "react";
import "./clientes.css";
const Clientes: React.FC = () => {
  return (
    <div className="cliente-container">
      <img src={`${process.env.PUBLIC_URL}/atima.png`} 
      width="114"
      height="46"
      className="d-inline-block align-top"
      alt="Imagem 1" />
      <img src={`${process.env.PUBLIC_URL}/cdm.png`} 
      width="114"
      height="46"
      className="d-inline-block align-top"
      
       alt="Imagem 2" />
    </div>
  );
};
export default Clientes;