import React from 'react';
import './direitos.css';

const Direitos = () => {
  return (
    <div className="footer-bar">
    <a href='#' className="footer-link">
      <img
        src={`${process.env.PUBLIC_URL}/logoneogenes.png`}
        width="59"
        height="59"
        className="footer-logo"
        alt="Neo Geness logo"
      />
      <span className="brand-text"> 
        Neo Geness  © 2024  
        - CNPJ. 47.733.109/0001-00 - © Todos os direitos reservados
      </span>
    </a>
    <a href={`${process.env.PUBLIC_URL}/politicaprivacidade.pdf`} target="_blank" rel="noopener noreferrer" className="footer-link">
      Política de Privacidade
    </a>
  </div>
  );
};

export default Direitos;
