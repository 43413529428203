import  { useState, useEffect } from 'react';

import "./App.css";
import Newsletter from "./components/newsletter/newsletter";
import NavigationBar from "./components/navbar/navbar";
import Hero from "./components/hero/hero";
import Clientes from "./components/clientes/clientes";
import Services from "./components/servicos/servicos";
import Tecnologias from "./components/tecnologias/tecnologias";
import Metodologia from "./components/metodologia/metodologia";
import Contato from "./components/contato/contato";
import Footer from "./components/footer/footer";
import Direitos from "./components/direitos/direitos";
import NewsletterBar from "./components/newsletterbar/newsletterbar";
import Loading from './components/load/load';

export type Language = 'pt' | 'en';

function App() {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [language, setLanguage] = useState<Language>('pt');

  useEffect(() => {
    // Identifica se o dispositivo é móvel
    const mobileCheck = window.matchMedia("(max-width: 768px)").matches;
    setIsMobile(mobileCheck);

    // Simula o carregamento de conteúdo
    setTimeout(() => {
      setFadeOut(true); // Inicia o fade out do carregamento
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Duração do fade out
    }, 3000); // Duração do carregamento
  }, []);

  return (
    <div className={`App ${loading ? 'loading' : ''}`}>
      {loading && <Loading />}
      <div className={`content ${fadeOut ? 'show' : ''}`}>
        {!isMobile && <Newsletter language={language} />}
        
        {/* Passe o estado language e a função setLanguage para o NavigationBar */}
        <NavigationBar language={language} setLanguage={setLanguage} />
        
        {/* Passe o estado language para o Hero */}
        <Hero language={language} />
        
        <Clientes />
        <Services language={language} />
        <Tecnologias language={language} />
        {!isMobile && <Metodologia language={language} />}
        {!isMobile && <Contato language={language}/>}
        <NewsletterBar language={language}/>
        <Footer language={language}/>
        <Direitos />
      </div>
    </div>
  );
}

export default App;
