import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./navbar.css"; // Opcional para customização adicional
import ModalForm from "../modalform/modalform";
import { ContactForm, ContactFormData } from "../formularios/fomularios";
import LanguageDropdown from "../languagedropdown/languagedropdown";
import { Language } from '../../App'; // Importe o tipo Language

interface NavigationBarProps {
  language: Language;
  setLanguage: (lang: Language) => void;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ language, setLanguage }) => {
  const [isVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const mobileCheck = window.matchMedia("(max-width: 768px)").matches;
    setIsMobile(mobileCheck);
  }, []);

  const handleLanguageChange = (lang: Language) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
  };

  if (!isVisible) {
    return null;
  }

  const handleFormSubmit = (data: ContactFormData) => {
    console.log('Dados recebidos do formulário:', data);
    handleCloseModal();
  };

  const contactForm = <ContactForm onSubmit={handleFormSubmit} language={language}/>;

  const translations = {
    pt: {
      brandText: "Neo Geness",
      contactText: "Contacte-se",
      modalTitle: "Entre em Contato para Iniciar Seu Projeto",
    },
    en: {
      brandText: "Neo Geness",
      contactText: "Contact Us",
      modalTitle: "Get in Touch to Start Your Project",
    }
  };

  return (
    <Navbar bg="white" expand="lg" className="custom-navbar">
      <Container>
        <Navbar.Brand href="#" style={{ width: 200, textAlign: isMobile ? 'center' : 'left' }}>
          <img
            src={`${process.env.PUBLIC_URL}/logoneogenes.png`}
            width="69"
            height="69"
            className="d-inline-block align-top"
            alt="Neo Genesis logo"
            style={{ display: 'block', margin: isMobile ? '-16px auto' : '0' }}
          />{" "}
          <span className="brand-text">{translations[language].brandText}</span>
        </Navbar.Brand>
        {!isMobile && (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                {//<div>
                  //<button className="subscribe-btn" onClick={handleShowModal}>
                    //<span>{translations[language].contactText}</span>
                  //</button>
                //</div>
                }


              </Nav>
            </Navbar.Collapse>
          </>
        )}
        <LanguageDropdown language={language} handleLanguageChange={handleLanguageChange} />
      </Container>
      <ModalForm
        show={showModal}
        handleClose={handleCloseModal}
        formComponent={contactForm}
        title={translations[language].modalTitle}
      />
    </Navbar>
  );
};

export default NavigationBar;
