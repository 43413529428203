import React from 'react';
import './metodologia.css'
import { SlideInSection } from '../slideInsection/slideInsection';
import { Language } from '../../App';

const sectionTitleTranslations = {
  pt: "Saiba como trabalhamos para entregar resultados excepcionais.",
  en: "Find out how we work to deliver exceptional results."
};
interface laguageProps {
  language: Language;
}

const developmentStagesTranslations = {
  pt: [
    { name: 'Planejamento', description: 'O planejamento é a fase inicial onde definimos os objetivos do projeto e criamos um plano estratégico para atingir esses objetivos. Nesta etapa, identificamos as necessidades dos stakeholders, estimamos custos e recursos, e estabelecemos um cronograma detalhado. O planejamento eficaz garante uma base sólida para todo o processo de desenvolvimento.' },
    { name: 'Análise', description: 'Na fase de análise, aprofundamos o entendimento dos requisitos do sistema. Realizamos entrevistas, workshops e revisões de documentação para coletar informações detalhadas. Analisamos os requisitos de negócio e técnicos para garantir que todas as necessidades sejam compreendidas e documentadas. Esta etapa é crucial para evitar lacunas e mal-entendidos no futuro.' },
    { name: 'Design', description: 'Durante a fase de design, transformamos os requisitos coletados em uma arquitetura de sistema detalhada. Criamos diagramas de fluxo, modelos de dados e protótipos de interface de usuário. O objetivo é definir a estrutura e os componentes do sistema, garantindo que todas as funcionalidades sejam integradas de forma coesa e eficiente.' },
    { name: 'Desenvolvimento', description: 'A fase de desenvolvimento é onde o design se transforma em código funcional. Nossos desenvolvedores trabalham para implementar todas as funcionalidades especificadas, utilizando as tecnologias e ferramentas adequadas. Esta etapa envolve programação, integração de componentes e criação de bancos de dados. Testes preliminares também são realizados para garantir a qualidade do código.' },
    { name: 'Testes e Integração', description: 'Na fase de testes e integração, verificamos se o sistema atende aos requisitos especificados. Realizamos diversos tipos de testes, incluindo testes unitários, de integração, de sistema e de aceitação pelo usuário. Identificamos e corrigimos bugs, garantindo que o sistema seja robusto e funcional. A integração é realizada para assegurar que todos os componentes funcionem harmoniosamente.' },
    { name: 'Manutenção', description: 'A fase de manutenção envolve o monitoramento contínuo e suporte do sistema após sua implantação. Realizamos atualizações, correções de bugs e melhorias conforme necessário. Nosso objetivo é garantir que o sistema permaneça eficiente, seguro e atualizado, atendendo às necessidades em constante evolução do seu negócio.' }
  ],
  en: [
    { name: 'Planning', description: 'Planning is the initial phase where we define project goals and create a strategic plan to achieve those goals. In this stage, we identify stakeholder needs, estimate costs and resources, and establish a detailed schedule. Effective planning provides a solid foundation for the entire development process.' },
    { name: 'Analysis', description: 'In the analysis phase, we delve deeper into understanding system requirements. We conduct interviews, workshops, and document reviews to gather detailed information. We analyze business and technical requirements to ensure all needs are understood and documented. This stage is crucial to avoid gaps and misunderstandings in the future.' },
    { name: 'Design', description: 'During the design phase, we transform the collected requirements into a detailed system architecture. We create flowcharts, data models, and user interface prototypes. The goal is to define the structure and components of the system, ensuring all functionalities are integrated cohesively and efficiently.' },
    { name: 'Development', description: 'The development phase is where the design turns into functional code. Our developers work to implement all specified functionalities using appropriate technologies and tools. This stage involves programming, component integration, and database creation. Preliminary tests are also conducted to ensure code quality.' },
    { name: 'Testing and Integration', description: 'In the testing and integration phase, we verify that the system meets the specified requirements. We conduct various types of tests, including unit tests, integration tests, system tests, and user acceptance tests. We identify and fix bugs, ensuring the system is robust and functional. Integration is performed to ensure all components work harmoniously.' },
    { name: 'Maintenance', description: 'The maintenance phase involves ongoing monitoring and support of the system after deployment. We perform updates, bug fixes, and improvements as needed. Our goal is to ensure the system remains efficient, secure, and up-to-date, meeting the continuously evolving needs of your business.' }
  ]
};






const Metodologia: React.FC<laguageProps> = ({ language }) =>  {
  const sectionTitle = sectionTitleTranslations[language];
  const developmentStages = developmentStagesTranslations[language];
  return (
    <div className="development-cycle-container">
      <h2 className="cycle-title"> {sectionTitle}</h2>
      <div className="cycle-content">
        <div className="cycle-column left">
          {developmentStages.slice(0, 3).map((stage, index) => (
            <SlideInSection key={index} direction="left">
              <h3 className="step-title left">{index + 1} - {stage.name}</h3>
              <p className="step-description left">{stage.description}</p>
            </SlideInSection>
          ))}
        </div>
        <img src={`${process.env.PUBLIC_URL}/ciclo.png`} alt="Ciclo de Desenvolvimento" className="cycle-image" />
        <div className="cycle-column right">
          {developmentStages.slice(3).map((stage, index) => (
            <SlideInSection key={index} direction="right">
              <h3 className="step-title right">{index + 4} - {stage.name}</h3>
              <p className="step-description right">{stage.description}</p>
            </SlideInSection>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Metodologia;


