import React from 'react';
import { Modal } from 'react-bootstrap';
import './modalform.css'; // Importa o CSS personalizado
interface ModalFormProps {
  show: boolean;
  handleClose: () => void;
  formComponent: React.ReactNode; // Aceita um componente de formulário como prop
  title: string; // Adicionei uma prop para o título do modal
}

const ModalForm: React.FC<ModalFormProps> = ({ show, handleClose, formComponent, title }) => {
  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal custom-modal-width">
      <div className="col-md-12"> {/* Classe col-md-12 aplicada */}
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formComponent}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ModalForm;
