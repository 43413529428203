import React, { useState } from "react";
import "./newsletter.css";
import ModalForm from "../modalform/modalform";
import { NewsletterForm, NewsletterFormData } from "../formularios/fomularios";
import { Language } from "../../App";// Supondo que você já tem essa interface



interface laguageProps {
  language: Language;
}
const newsletterTranslations = {
  pt: {
    message: "Inscreva-se na nossa newsletter e receba conteúdos exclusivos.",
    buttonText: "Escreva-se",
    modalTitle: "Junte-se à Nossa Comunidade: Assine a Newsletter"
  },
  en: {
    message: "Subscribe to our newsletter and receive exclusive content.",
    buttonText: "Subscribe",
    modalTitle: "Join Our Community: Subscribe to the Newsletter"
  }
};

const Newsletter: React.FC<laguageProps> = ({ language }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setIsVisible(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  if (!isVisible) {
    return null;
  }

  const handleFormSubmit = (data: NewsletterFormData) => {
    console.log('Dados recebidos do formulário:', data);
    handleCloseModal(); // Fecha o modal após submissão
  };

  const newsletterForm = <NewsletterForm onSubmit={handleFormSubmit} language={language}/>;

  return (
    <div className="newsletter-container">
      <button className="close-button" onClick={handleClose}>
        &times;
      </button>
      <div className="row align-items-center">
        <div className="col-md-10 text-md-left">
          <span>
            {newsletterTranslations[language].message}
          </span>
        </div>
        <div className="col-md-2 text-md-right">
          <button className="newsletter-button" onClick={handleShowModal}>
            <span>{newsletterTranslations[language].buttonText}</span>
          </button>
        </div>
      </div>
      <ModalForm
        show={showModal}
        handleClose={handleCloseModal}
        formComponent={newsletterForm}
        title={newsletterTranslations[language].modalTitle}
      />
    </div>
  );
};

export default Newsletter;
