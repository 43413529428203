import React, { useState } from "react";
import './contato.css'
import { SlideInSection } from '../slideInsection/slideInsection';
import ModalForm from "../modalform/modalform";
import { ContactForm, ContactFormData } from "../formularios/fomularios";
import { Language } from '../../App';


const contactTranslations = {
  pt: {
    title: "Transforme seu Negócio com a Neo Geness!",
    description: "Na Neo Geness, acreditamos no poder da inovação para transformar negócios. Nossas soluções personalizadas são projetadas para atender às necessidades específicas da sua empresa, ajudando você a alcançar novos patamares de sucesso. Não espere mais para fazer a diferença. Entre em contato conosco hoje mesmo e descubra como podemos impulsionar seu negócio!",
    buttonText: "Fale conosco",
    modalTitle: "Entre em Contato para Iniciar Seu Projeto"
  },
  en: {
    title: "Transform Your Business with Neo Geness!",
    description: "At Neo Geness, we believe in the power of innovation to transform businesses. Our custom solutions are designed to meet the specific needs of your company, helping you reach new heights of success. Don't wait any longer to make a difference. Contact us today and find out how we can boost your business!",
    buttonText: "Contact us",
    modalTitle: "Get in Touch to Start Your Project"
  }
};


interface laguageProps {
  language: Language;
}

const Contato: React.FC<laguageProps> = ({ language }) => {
  const [isVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  if (!isVisible) {
    return null;
  }

  const handleFormSubmit = (data: ContactFormData) => {
    console.log('Dados recebidos do formulário:', data);
    handleCloseModal(); // Fecha o modal após submissão
  };

  const contactForm = <ContactForm onSubmit={handleFormSubmit} language={language}/>;

  return (
    <div className="contact-section">
      <div className="contact-content">
        <div className="contact-image">
          <SlideInSection direction="left">
            <img src={`${process.env.PUBLIC_URL}/contact.png`} alt="Contato" />
          </SlideInSection>
        </div>
        <div className="contact-text">
          <SlideInSection direction="right">
            <h2>{contactTranslations[language].title}</h2>
            <p>{contactTranslations[language].description}</p>
            <button className="contact-button" onClick={handleShowModal}>
              {contactTranslations[language].buttonText}
            </button>
          </SlideInSection>
        </div>
      </div>
      <ModalForm
        show={showModal}
        handleClose={handleCloseModal}
        formComponent={contactForm}
        title={contactTranslations[language].modalTitle}
      />
    </div>
  );
};




export default Contato;
