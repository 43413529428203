import React, { useState } from "react";
import './newsletterbar.css';
import ModalForm from "../modalform/modalform";
import { NewsletterForm, NewsletterFormData } from "../formularios/fomularios";
import { Language } from '../../App';
const newsletterBarTranslations = {
  pt: {
    message: "Fique por dentro das últimas novidades e tendências! Inscreva-se na nossa newsletter e receba conteúdos exclusivos.",
    buttonText: "Escreva-se",
    modalTitle: "Junte-se à Nossa Comunidade: Assine a Newsletter"
  },
  en: {
    message: "Stay updated with the latest news and trends! Subscribe to our newsletter and receive exclusive content.",
    buttonText: "Subscribe",
    modalTitle: "Join Our Community: Subscribe to the Newsletter"
  }
};


interface laguageProps {
  language: Language;
}

const NewsletterBar: React.FC<laguageProps> = ({ language }) => {
  const [isVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  if (!isVisible) {
    return null;
  }

  const handleFormSubmit = (data: NewsletterFormData) => {
    console.log('Dados recebidos do formulário:', data);
    handleCloseModal(); // Fecha o modal após submissão
  };

  const newsletterForm = <NewsletterForm onSubmit={handleFormSubmit} language={language} />;

  return (
    <div className="newsletter-bar">
      <div className="row align-items-center">
        <div className="col-md-10 text-md-left">
          <span>{newsletterBarTranslations[language].message}</span>
        </div>
        <div className="col-md-2 text-md-right">
          <button className="newsletter3-button" onClick={handleShowModal}>
            {newsletterBarTranslations[language].buttonText}
          </button>
        </div>
      </div>
      <ModalForm
        show={showModal}
        handleClose={handleCloseModal}
        formComponent={newsletterForm}
        title={newsletterBarTranslations[language].modalTitle}
      />
    </div>
  );
};

export default NewsletterBar;
