import React from "react";
import { Dropdown } from "react-bootstrap";

import './languagedropdown.css'
// Define o tipo para as linguagens
type Language = 'pt' | 'en';

interface LanguageDropdownProps {
  language: Language;
  handleLanguageChange: (lang: Language) => void;
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({ language, handleLanguageChange }) => (
  <Dropdown onSelect={(lang) => handleLanguageChange(lang as Language)} style={{ marginLeft: 12}}>
    <Dropdown.Toggle variant="link" id="dropdown-basic" className="language-selector">
      <img
        src={language === 'pt' ? `${process.env.PUBLIC_URL}/brasil.webp` : `${process.env.PUBLIC_URL}/estadosunidos.webp`}
        alt={language === 'pt' ? 'Português' : 'English'}
        className="language-flag"
      />
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item eventKey="pt">
        <img src={`${process.env.PUBLIC_URL}/brasil.webp`} alt="Português" className="dropdown-flag" /> Português
      </Dropdown.Item>
      <Dropdown.Item eventKey="en">
        <img src={`${process.env.PUBLIC_URL}/estadosunidos.webp`} alt="English" className="dropdown-flag" /> English
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

export default LanguageDropdown;
