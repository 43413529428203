import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
interface SlideInSectionProps {
    children: React.ReactNode;
    direction: 'left' | 'right';
}

export const SlideInSection: React.FC<SlideInSectionProps> = ({ children, direction }) => {
    const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });
  
    const variants = {
      hidden: { opacity: 0, x: direction === 'left' ? -100 : 100 },
      visible: { opacity: 1, x: 0, transition: { duration: 0.6 } }
    };
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        variants={variants}
        className="cycle-step"
      >
        {children}
      </motion.div>
    );
  };
