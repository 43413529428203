import { useState } from 'react';
import emailjs from 'emailjs-com';

interface FormData {
    name: string,
    email: string,
    phone: string,
    company:string,
    projectType: string,
    budget:string,
    message: string
}

interface FormDataNewLlater {
  email: string,
}
interface UseEmailAPI {
  sendEmail: (formData: FormData) => Promise<void>;
  sendEmailNewllater: (formData: FormDataNewLlater) => Promise<void>;
  loading: boolean;
  error: string | null;
  success: boolean;
}

const useEmailAPI = (): UseEmailAPI => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const sendEmail = async (formData: FormData): Promise<void> => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const data: Record<string, unknown> = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      company:formData.company,
      projectType: formData.projectType,
      budget: formData.budget,
      message: formData.message,
    };

    try {
      const response = await emailjs.send(
        process.env.REACT_APP_SERVICE_ID as string,   // Coloque aqui seu Service ID
        process.env.REACT_APP_TEMPLATE_ID as string,  // Coloque aqui seu Template ID
        data,
        process.env.REACT_APP_USER_ID as string       // Coloque aqui seu User ID
      );
      console.log('Email enviado com sucesso!', response.status, response.text);
      setSuccess(true);
    } catch (err) {
      console.error('Erro ao enviar email:', err);
      setError('Ocorreu um erro ao enviar o e-mail.');
    } finally {
      setLoading(false);
    }
  };
  


  const sendEmailNewllater = async (formData: FormDataNewLlater): Promise<void> => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    const data: Record<string, unknown> = {
      email: formData.email
    };

    try {
      const response = await emailjs.send(
        process.env.REACT_APP_SERVICE_ID as string,   // Coloque aqui seu Service ID
        'template_46b1qan',  // Coloque aqui seu Template ID
        data,
        process.env.REACT_APP_USER_ID as string       // Coloque aqui seu User ID
      );
      console.log('Email enviado com sucesso!', response.status, response.text);
      setSuccess(true);
    } catch (err) {
      console.error('Erro ao enviar email:', err);
      setError('Ocorreu um erro ao enviar o e-mail.');
    } finally {
      setLoading(false);
    }
  };


  return { sendEmail, sendEmailNewllater, loading, error, success };
};

export default useEmailAPI;
