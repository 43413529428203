// src/components/Hero.tsx
import React, { useState } from "react";
import './hero.css';  // Para customização adicional
import ModalForm from "../modalform/modalform";
import { ContactForm, ContactFormData } from "../formularios/fomularios";
import { Language } from "../../App";

const heroTranslations = {
  pt: {
    title: "Desenvolvimento de Software",
    subtitle: "Inovação tecnológica que transcende o digital",
    description: "Criamos software personalizado e robusto, adaptado às suas necessidades de negócios.",
    buttonText: "Fale Conosco",
    modalTitle: "Entre em Contato para Iniciar Seu Projeto"
  },
  en: {
    title: "Software Development",
    subtitle: "Technological innovation that transcends digital",
    description: "We create custom and robust software tailored to your business needs.",
    buttonText: "Contact Us",
    modalTitle: "Get in Touch to Start Your Project"
  }
};







export interface laguageProps {
  language: Language;
}

const Hero: React.FC<laguageProps> = ({ language }) => {
  const [isVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFormSubmit = (data: ContactFormData) => {
    console.log('Dados recebidos do formulário:', data);
    handleCloseModal(); // Fecha o modal após submissão
  };

  const contactForm = <ContactForm onSubmit={handleFormSubmit} language={language}/>;

  if (!isVisible) {
    return null;
  }

  return (
    <div className="hero-section">
      <div className="hero-content">
        <div className="hero-text">
          <h3 className="hero-title">{heroTranslations[language].title}</h3>
          <h2 className="hero-subtitle">{heroTranslations[language].subtitle}</h2>
          <p className="hero-description">
            {heroTranslations[language].description}
          </p>
          <button className="hero-button" onClick={handleShowModal}>
            {heroTranslations[language].buttonText}
          </button>
        </div>
      </div>
      <ModalForm
        show={showModal}
        handleClose={handleCloseModal}
        formComponent={contactForm}
        title={heroTranslations[language].modalTitle}
      />
    </div>
  );
};

export default Hero;

